import { useSavedFilter } from '@capturi/api-filters'
import { useScore } from '@capturi/api-scoring'
import { useCurrentUser } from '@capturi/core'
import {
  PhoneFilter,
  PhoneSegmentBuilderState,
  hasFilterValues,
  toDuration,
  toFilterValues,
  useFilterDefinitions,
} from '@capturi/filters'
import { getFilterInfo } from '@capturi/scoring'
import { UserPermissionsList } from '@capturi/sharing'
import { useUsers } from '@capturi/stores'
import { Button, Description, Highlight } from '@capturi/ui-components'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  Icon,
  IconProps,
  List,
  ListIcon,
  ListItem,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import ProcessingTag from 'components/ProcessingTag'
import { switchPermissionPreset } from 'pages/analytics/trackers/utils'
import React from 'react'
import {
  MdEdit,
  MdHistory,
  MdPowerSettingsNew,
  MdShare,
  MdTimer,
  MdUnfoldLess,
  MdUnfoldMore,
} from 'react-icons/md'
import { useNavigate } from 'react-router'

import { logEvent } from '../events'
import { useIsProcessingCurrentPeriod } from '../hooks/useIsProcessingCurrentPeriod'
import { routes } from '../routes'

export type ScoreConfigurationPanelProps = {
  uid: string
}

const clampLine = css`
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const ScoreConfigurationPanel: React.FC<
  ScoreConfigurationPanelProps
> = ({ uid }) => {
  const [shouldDirtyPoll, setShouldDirtyPoll] = React.useState(false)
  const { data: score, dataUpdatedAt } = useScore(
    uid,
    shouldDirtyPoll ? 7000 : 0,
  )

  React.useEffect(() => {
    if (!score) return
    if (score.isProcessing) {
      if (!shouldDirtyPoll) {
        setShouldDirtyPoll(true)
      }
    } else {
      if (shouldDirtyPoll) {
        setShouldDirtyPoll(false)
      }
    }
  }, [score, shouldDirtyPoll])

  const navigate = useNavigate()
  const { getUserByUid } = useUsers()
  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)

  const { data: savedFilter } = useSavedFilter({
    uid: score?.savedFilterGroupUid,
  })
  const segmentation: PhoneSegmentBuilderState = {
    channel: 'phone',
    savedFilter: savedFilter,
    values: savedFilter?.values
      ? toFilterValues(savedFilter?.values ?? {})
      : score?.filters ?? {},
  }

  const isScoreProcessing = useIsProcessingCurrentPeriod(
    score?.isProcessing ?? false,
    score?.processingProgress ?? null,
  )

  const doNavigateToUrl = (): void => {
    navigate(routes.editScore(uid))
  }

  return (
    <Flex direction="column" borderRadius="md">
      <Accordion allowToggle>
        <AccordionItem border="none">
          {({ isExpanded }) => (
            <>
              <Flex
                flexGrow="1"
                py="1"
                pr="2"
                gap="2"
                alignItems="center"
                justifyContent="flex-end"
              >
                {isScoreProcessing && <ProcessingTag ml={2} />}
                <AccordionButton
                  w="auto"
                  py="1"
                  pl="0"
                  px="3"
                  alignItems="center"
                  _focus={{ outline: undefined }}
                  _hover={{ bg: 'unset' }}
                >
                  <Flex py="1" alignItems="center">
                    {isExpanded ? (
                      <MdUnfoldLess fontSize="14" cursor="pointer" />
                    ) : (
                      <MdUnfoldMore fontSize="14" cursor="pointer" />
                    )}

                    <Text
                      ml="2"
                      size="lg"
                      fontSize="sm"
                      fontWeight="medium"
                      cursor="pointer"
                    >
                      <Trans>Show details</Trans>
                    </Text>
                  </Flex>
                </AccordionButton>
                <Tooltip
                  label={t`You don’t have edit rights for this score`}
                  hasArrow
                  placement="top"
                  isDisabled={
                    currentUser.isAdminOrTeamLead &&
                    currentUser.permissions.editScore &&
                    score?.accessLevel === 'Edit'
                  }
                >
                  <Button
                    isDisabled={score?.accessLevel !== 'Edit'}
                    ml={4}
                    leftIcon={<Icon as={MdEdit} boxSize={4} />}
                    onClick={() => {
                      logEvent('edit-score--clicked')
                      doNavigateToUrl()
                    }}
                  >
                    <Trans>Edit score</Trans>
                  </Button>
                </Tooltip>
              </Flex>
              <AccordionPanel
                backgroundColor="#fff"
                py="1"
                px="4"
                border="1px solid"
                borderColor="gray.200"
                borderRadius="4px"
              >
                <Grid
                  templateColumns="1fr 1px .7fr"
                  columnGap={2}
                  fontSize="sm"
                >
                  <Box py="3" fontWeight="medium" px="1">
                    <Box mb="4">
                      <Box>
                        <Highlight>
                          <Trans>Data basis</Trans>
                        </Highlight>
                        {hasFilterValues(segmentation.values) ? (
                          <PhoneFilter
                            key={dataUpdatedAt}
                            filterDefinitions={filterDefinitions}
                            state={
                              !segmentation.savedFilter
                                ? {
                                    ...segmentation,
                                    values: {
                                      ...segmentation.values,
                                      duration: toDuration(
                                        segmentation?.values.duration?.min,
                                        segmentation?.values.duration?.max,
                                      ),
                                    },
                                  }
                                : segmentation
                            }
                            isReadOnly
                          />
                        ) : (
                          <Description>
                            <Trans>
                              All conversations are scored by this configuration
                            </Trans>
                          </Description>
                        )}
                      </Box>
                    </Box>
                    <Center height="20px">
                      <Divider orientation="horizontal" />
                    </Center>
                    <Box>
                      <Highlight>
                        <Trans>Criteria</Trans>
                      </Highlight>
                      <List spacing={2} mt={2}>
                        {score?.parameters.map((x) => (
                          <ListItem
                            key={x.uid}
                            display="flex"
                            alignItems="center"
                          >
                            <ListBullet border="1px" borderColor="gray.500" />
                            <ScoreParameterItem
                              key={x.type}
                              {...getFilterInfo(x, filterDefinitions)}
                              weight={x.weight}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Box>

                  <Box height="100%" bg="gray.200" />
                  <Box py="3" pl="3">
                    {score?.description && (
                      <Box mb={3}>
                        <Highlight>
                          <Text fontSize="md">
                            <Trans>Description</Trans>
                          </Text>
                        </Highlight>
                        <Description>{score.description}</Description>
                      </Box>
                    )}
                    <Flex justify="space-between" align="center" mb="4">
                      <Box display="flex" alignItems="center">
                        <MdShare size="14" />
                        <Text fontWeight="medium" ml="8px">
                          <Trans>Sharing</Trans>
                        </Text>
                      </Box>
                      {score?.acl && score?.acl.length > 0 ? (
                        <UserPermissionsList acl={score.acl} isAccordion={true}>
                          <Box
                            px="2"
                            bg="gray.300"
                            fontWeight="medium"
                            borderRadius="4"
                          >
                            {switchPermissionPreset(score?.permissionPreset)}
                          </Box>
                        </UserPermissionsList>
                      ) : (
                        <Box
                          px="2"
                          bg="gray.300"
                          fontWeight="medium"
                          borderRadius="4"
                        >
                          {score && (
                            <Text>
                              {switchPermissionPreset(score?.permissionPreset)}
                            </Text>
                          )}
                        </Box>
                      )}
                    </Flex>
                    <Flex justify="space-between" align="center" mb="4">
                      <Box display="flex" alignItems="center">
                        <MdTimer size="14" />
                        <Text fontWeight="medium" ml="8px">
                          <Trans>Active</Trans>
                        </Text>
                      </Box>
                      {/* <Box
                          px="2"
                          bg="gray.300"
                          fontWeight="medium"
                          borderRadius="4"
                        >
                          {getTimeFilterString(tracker.timeFilter)}
                        </Box> */}
                    </Flex>

                    <Flex justify="space-between" align="center" gap="2" mb="4">
                      <Box display="flex" alignItems="center">
                        <MdPowerSettingsNew size="14" />
                        <Text fontWeight="medium" ml="8px">
                          <Trans>Created</Trans>
                        </Text>
                      </Box>
                      <Box
                        px="2"
                        bg="gray.300"
                        fontWeight="medium"
                        borderRadius="4"
                        css={clampLine}
                      >
                        {score && (
                          <Trans>
                            {i18n.date(score.created, { dateStyle: 'long' })} by{' '}
                            {getUserByUid(score.createdByUserUid).name}
                          </Trans>
                        )}
                      </Box>
                    </Flex>
                    <Flex justify="space-between" align="center" gap="2" mb="4">
                      <Box display="flex" alignItems="center">
                        <MdHistory size="14" />
                        <Text fontWeight="medium" ml="8px">
                          <Trans>Updated</Trans>
                        </Text>
                      </Box>
                      <Box
                        px="2"
                        bg="gray.300"
                        fontWeight="medium"
                        borderRadius="4"
                        css={clampLine}
                      >
                        {score && (
                          <Trans>
                            {i18n.date(score.updated, { dateStyle: 'long' })} by{' '}
                            {getUserByUid(score.updatedByUserUid).name}
                          </Trans>
                        )}
                      </Box>
                    </Flex>
                  </Box>
                </Grid>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}

const ScoreParameterItem: React.FC<{
  icon: React.ComponentType
  label: React.ReactNode
  weight: number
}> = ({ icon, label, weight }) => {
  return (
    <Flex
      align="center"
      border="1px"
      borderColor="gray.300"
      borderRadius="md"
      px={2}
      py={1}
      minW={0}
    >
      <Icon as={icon} mr={1} />
      <Text fontSize="sm" display="flex" flexWrap="wrap">
        <span>{label}</span>
      </Text>
      <Highlight ml={1}>+{weight}</Highlight>
    </Flex>
  )
}

const ListBullet: React.FC<IconProps> = (props) => (
  <ListIcon
    as={Box}
    bg="gray.200"
    display="inline-block"
    boxSize={2}
    borderRadius="2px"
    {...props}
  />
)
